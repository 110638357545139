/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Me encantan las pequeñas editoriales que recibo cada mes con Elektor.POST en el correo, este mes hablan de exploración espacial, un tema del que ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2010/12/11/el-futuro-de-la-exploracion-espacial-esta-en-las-empresas/"
  }, "ya hemos hablado en este blog"), " y que ", React.createElement(_components.a, {
    href: "http://enlaces.entramado.net/tags.php/espacio"
  }, "se está poniendo cada vez más candente"), ", ahí va la reflexión:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Crónicas Marcianas"), "\n", React.createElement(_components.p, null, "La semana pasada, el vehículo explorador Curiosity entonó un solitario “cumpleaños feliz” en Marte, tras pasar un año en el planeta rojo. Aunque no esté relacionado directamente, cosas como ésta me hacen pensar en el futuro de la exploración espacial. Es muy probable que se base en financiación privada, al margen de la burocracia y en algunos casos hasta un poco bajo la filosofía del “hazlo tú mismo”. La lista de empresas privadas dedicadas es impresionante, incluyendo las fundadas por célebres emprendedores como Richard Branson, Elon Musk, Burt Rutan, e incluso John Carmack (así es, una figura clave en la industria de los videojuegos). En los años 50, pretender diseñar nuestro propio sistema domótico habría sonado un poco a historia de ciencia ficción de Ray Bradbury: imposible. Hoy en día, la idea de explorar el suelo marciano cómodamente desde el sofá, con nuestro propio rover suena un poco… en fin… ¿lo adivináis? Jaime | Elektor"), "\n"), "\n", React.createElement(_components.p, null, "PS: Se me olvidaba enlazar ", React.createElement(_components.a, {
    href: "http://asincronia.entramado.net/crowdfunding-cientifico-mineria-y-telescopios-espaciales-de-uso-comercial/"
  }, "ésta entrada de Pablo"), " que nos viene al pelo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
